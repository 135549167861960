import request from '@/util/http.js'

/**
 * 轮播图
 */
export async function getBannerList () {
  const result = await request.get('/dpi/v2/findBannerInfo?type=M')
  return result.data || []
}

/**
 *获取所有根类型
 */
export async function getAllType () {
  const result = await request.get('/dpi/v2/findTypeInfoByRoot')
  return result.data || []
}

/**
 *获取热门类别
 */
export async function getHotType () {
  const result = await request.get('/dpi/v2/findTypeInfoByHot')
  return result.data || []
}

/**
 *获取热门物料
 */
export async function getHotMatter () {
  const result = await request.get('/dpi/v2/findMatterInfoByHot')
  return result.data || []
}

/**
 * 获取所有二级类型
 */
export async function getSecTypeAll () {
  const result = await request.get('/dpi/v2/findTypeInfoByAll')
  return result.data || []
}

/**
 *获取当前类别下所有物料
 * @param {*} id
 */
export async function getMatterList (id) {
  const result = await request.get('/dpi/v2/findMatterInfoByTypeIdAll', {
    typeId: id
  })
  return result.data || []
}

/**
 * 搜索物料信息
 * @param {*} param0
 */
export async function getSearchList ({
  keyword,
  pageNumber = 1,
  pageSize = 10
}) {
  const result = await request.get('/dpi/v2/searchMatterInfoByName', {
    name: keyword,
    currentPage: pageNumber,
    pageSize
  })
  return {
    pageNumber: result.data.currentPage,
    list: result.data.data,
    pageSize: result.data.pageSize,
    totalPage: result.data.totalPage,
    totalRow: result.data.resourceSize
  }
}

/**
 * 根据id获取一个物料
 * @param {*} id
 */
export async function getMatterInfo (id) {
  const result = await request.get('/dpi/v2/findMatterInfoById', { id })
  return result.data
}

/**
 * 获取物料的历史数据
 * @param {*} param0
 */
export async function getMatterDataHistory ({ id, currentPage = 1 }) {
  const result = await request.get('/dpi/findMatterDataHistory', {
    id,
    currentPage
  })
  return result.data || []
}
