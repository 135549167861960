<template>
  <div v-if="typeList.length > 0"
       class="home_section_two">
    <div class="app_section_title">
      <span class="title_content">热门回收品类</span>
      <a @click.prevent="goTypeView">查看全部</a>
    </div>
    <div class="type_list">
      <div class="perType"
           v-for="(type, index) of typeList"
           :key="index"
           @click="handleTypeClick(type.id)">
        <img v-lazy="type.imgUrl">
        <span>{{type.typeName}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getHotType } from '@/api'
import { mapMutations } from 'vuex'
export default {
  name: 'home-section-two',
  data () {
    return {
      typeList: [], // {id,typeName,imgUrl}
    }
  },
  methods: {
    ...mapMutations({
      setPendding: 'SET_HOMESECTION_PENDING'
    }),
    goTypeView () {
      this.$store.commit('SET_TYPEVIEWOBJ', {})
      this.$router.push({ name: 'type' })
    },
    handleTypeClick (id) {
      this.$store.commit('SET_TYPEVIEWOBJ', { typeId: id })
      this.$router.push({ name: 'type' })
    },
    async fetchType () {
      try {
        let list = await getHotType()
        this.typeList = list.reduce((arr, item, index) => {
          if (index > 5) return arr
          let rootType = {
            id: item.id,
            typeName: item.typeName,
            imgUrl: item.micon || item.icon
          }
          arr.push(rootType)
          return arr
        }, [])
      } catch (e) {
        console.log(e)
      }
    }
  },
  async mounted () {
    await this.fetchType()
    this.setPendding({ num: 2, bool: false })
  }
}
</script>

<style lang="scss">
.home_section_two {
  background-color: #fff;
  margin-bottom: 10px;
  @include pagePadding();
  padding-top: 15px;
  padding-bottom: 15px;
  .type_list {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
  }
  .perType {
    width: 33%;
    overflow: hidden;
    padding-top: 15px;
    img {
      display: block;
      margin: 0 auto;
      width: 45px;
      height: 45px;
    }
    span {
      display: inline-block;
      width: 100%;
      @include text-ellipsis();
      text-align: center;
      font-size: 12px;
      color: $fc-sub;
      margin-top: 10px;
    }
  }
}
</style>
