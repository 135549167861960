import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/HomeView.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/type',
      name: 'type',
      component: () =>
        import(/* webpackChunkName: "type" */ './views/TypeView.vue')
    },
    {
      path: '/recommend',
      name: 'recommend',
      component: () =>
        import(/* webpackChunkName: "recommend" */ './views/RecommendView.vue')
    },
    {
      path: '/buyer',
      name: 'buyer',
      component: () =>
        import(/* webpackChunkName: "buyer" */ './views/BuyerView.vue')
    },
    {
      path: '/service',
      name: 'service',
      component: () =>
        import(/* webpackChunkName: "service" */ './views/ServiceView.vue')
    },
    {
      path: '/company',
      name: 'company',
      component: () =>
        import(/* webpackChunkName: "company" */ './views/CompanyView.vue')
    },
    {
      path: '/search',
      name: 'search',
      component: () =>
        import(/* webpackChunkName: "search" */ './views/SearchView.vue')
    },
    {
      path: '/shop',
      name: 'shop',
      component: () =>
        import(/* webpackChunkName: "shop" */ './views/ShopView.vue')
    },
    {
      path: '/map/:position',
      name: 'map',
      component: () =>
        import(/* webpackChunkName: "map" */ './views/MapView.vue')
    },
    {
      path: '/matter/:id',
      name: 'matter',
      component: () =>
        import(/* webpackChunkName: "matter" */ './views/MatterView.vue')
    }
  ]
})
