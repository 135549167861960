import Vue from 'vue'
import Vuex from 'vuex'
import * as Api from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    homeSection_1_pending: true,
    homeSection_2_pending: true,
    homeSection_3_pending: true,
    //
    rootTypeList: {
      update: false,
      list: []
    },
    typeViewObj: {
      rootId: '',
      typeId: ''
    }
  },
  getters: {
    homePagePendding (state) {
      return (
        state.homeSection_1_pending ||
        state.homeSection_2_pending ||
        state.homeSection_3_pending
      )
    }
  },
  mutations: {
    SET_HOMESECTION_PENDING (state, { num, bool }) {
      state[`homeSection_${num}_pending`] = bool
    },
    UPDATE_ROOTTYPELIST (state, list = []) {
      state.rootTypeList.update = true
      state.rootTypeList.list = list
    },
    SET_TYPEVIEWOBJ (state, { rootId = '', typeId = '' }) {
      state.typeViewObj = Object.assign(state.typeViewObj, { rootId, typeId })
    }
  },
  actions: {
    async getAllRootType ({ commit, state }, params) {
      try {
        let list = await Api.getAllType()
        commit(
          'UPDATE_ROOTTYPELIST',
          list.map(type => {
            let { id, typeName, icon } = type
            return { id, typeName, icon }
          })
        )
      } catch (e) {
        console.log(e)
      }
    }
  }
})
