import axios from 'axios'
import app from '../index'
// var PROD_URL = process.env.BASE_URL;//process.env.BASE_URL在webpack中配置。dev.env.js为测试环境，prod.env.js为发布环境
// axios.defaults.baseURL = PROD_URL // 配置 apiURL
axios.defaults.timeout = 50000 // 超时
// http request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)
// Add a response interceptor

axios.interceptors.response.use(
  function (response) {
    let res = response.data
    // 通过状态码来识别服务器提示信息
    let code = res.status

    switch (code) {
      case 200:
        break
      default:
        break
    }

    if (code >= 500) {
      // app toast msg
      app.$toast(res.msg || res.message)
      return Promise.reject(res)
    }
    return res
  },
  function (error) {
    // 非状态码错误  在此通过正则处理
    console.log('捕获到一个错误，错误信息：' + error)
    if (/Network Error/i.test(error)) {
      alert('您当前无法上网，请检查你的移动数据开关或wifi是否正常')
    }
    if (/ms exceeded/i.test(error)) {
      alert('您的网络连接不稳定，请检查你的移动数据开关或wifi是否正常')
    }
    if (/code 500/i.test(error)) {
      console.log('网络异常，请稍后重试', error)
      // return window.location.replace(`${window.location.origin}/500`)
      // return app.$router.push({ path: '/500' })
      app.$toast('服务端异常')
    }
    return Promise.reject(error)
  }
)

/**
 * 对params进行编码格式如下：
 * key=value&key1=value1
 */
function encodeParams (params) {
  let str = ''
  if (Object.keys(params).length > 0) {
    for (let name in params) {
      if (params[name] == null) {
        str += `&${name}=`
      } else {
        str += `&${name}=${encodeURIComponent(params[name])}`
      }
    }
    str = str.substring(1)
  }
  return str
}

let instance = {
  get (url = '', params = {}) {
    let _encodeParams = encodeParams(params)
    _encodeParams && (url += `?${_encodeParams}`)
    return axios.get(url)
  },
  post (url = '', params = {}, emulateJSON = true) {
    let data
    // config.timeout = 5000;
    emulateJSON ? (data = params) : (data = encodeParams(params))

    return axios.post(url, data)
  }
}
export default instance
