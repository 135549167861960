<template>
  <div class="home_section_four">
    <div class="app_section_title">
      <span class="title_content">网萌回收承诺</span>
    </div>
    <div class="home_section_four_top">
      <div class="section_four_per">
        <div class="per1"></div>
        <p>商务证书 公安备案</p>
      </div>
      <div class="section_four_per">
        <div class="per2"></div>
        <p>线上网络 线下渠道</p>
      </div>
    </div>
    <div class="home_section_four_bottom">
      <div class="section_four_per">
        <div class="per3"></div>
        <p>保护隐私安全</p>
        <span>专业团队操作</span>
      </div>
      <div class="section_four_per">
        <div class="per4"></div>
        <p>专享上门服务</p>
        <span>上门/上店/快递回收</span>
      </div>
      <div class="section_four_per">
        <div class="per5"></div>
        <p>价格公道</p>
        <span>评估属实 绝不压价</span>
      </div>
      <div class="section_four_per">
        <div class="per6"></div>
        <p>快速打款</p>
        <span>交易成功 快速打款</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home-section-four',
}
</script>

<style lang="scss">
.home_section_four {
  background-color: #fff;
  @include pagePadding();
  padding-top: 15px;

  .home_section_four_top {
    border-bottom: 1px solid #eee;
  }
  .home_section_four_top,
  .home_section_four_bottom {
    display: flex;
    flex-flow: wrap;
  }
  .section_four_per {
    flex: 0 0 auto;
    width: 50%;
    text-align: center;
    padding: 30px 0 30px;
    div {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      height: 40px;
      margin-bottom: 30px;
      &.per1 {
        background-image: url('../assets/img/section4_1.jpg');
        background-size: 70% 150%;
      }
      &.per2 {
        background-image: url('../assets/img/section4_2.jpg');
        background-size: 70% 150%;
      }
      &.per3 {
        background-image: url('../assets/img/section4_3.png');
      }
      &.per4 {
        background-image: url('../assets/img/section4_4.png');
      }
      &.per5 {
        background-image: url('../assets/img/section4_5.png');
      }
      &.per6 {
        background-image: url('../assets/img/section4_6.png');
      }
    }
    p {
      font-weight: 800;
    }
    span {
      color: $fc-sub;
      padding-top: 10px;
      font-size: 12px;
      display: inline-block;
    }
  }
  .home_section_four_bottom {
    .section_four_per {
      div {
        margin-bottom: 15px;
      }
    }
  }
}
</style>
