<template>
  <div class="home_section_one">
    <div class="home_section_one_top">
      <van-swipe v-if="bannerList.length > 0"
                 class="home_banner">
        <van-swipe-item v-for="(banner, index) in bannerList"
                        :key="index">
          <a target="_blank"
             :href="banner.link?banner.link:'javascript:void(0)'">
            <img :src="banner.imgUrl" />
          </a>
        </van-swipe-item>
      </van-swipe>
      <div class="just_show">
        <div @click="$router.push({name:'buyer'})">
          <p>1000家+</p>
          <span>工厂选择了我们</span>
        </div>
        <div>
          <p>500万+</p>
          <span>我们的成交金额</span>
        </div>
      </div>
    </div>
    <div v-if="rootTypeList.length > 0"
         class="home_section_one_bottom">
      <div class="app_section_title">
        <span class="title_content">您可以卖</span>
      </div>
      <div class="rootType_list">
        <div class="perType"
             v-for="(type, index) of rootTypeList"
             :key="index"
             @click="handleRootTypeClick(type.id)">
          <img v-lazy="type.imgUrl">
          <span>{{type.typeName}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBannerList } from '@/api'
import { Swipe, SwipeItem } from 'vant';
import { mapMutations } from 'vuex'
export default {
  name: 'home-section-one',
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem
  },
  data () {
    return {
      bannerList: [], // {imgUrl,link}
      rootTypeList: [], // {id,typeName,imgUrl}
    }
  },
  computed: {
    rootTypeArr () {
      return this.$store.state.rootTypeList.list
    }
  },
  methods: {
    ...mapMutations({
      setPendding: 'SET_HOMESECTION_PENDING'
    }),
    handleRootTypeClick (id) {
      this.$store.commit('SET_TYPEVIEWOBJ', { rootId: id })
      this.$router.push({ name: 'type' })
    },
    async fetchBannerList () {
      try {
        let list = await getBannerList()
        this.bannerList = list.map((item) => {
          return {
            imgUrl: item.bannerPath,
            link: item.bannerUrl
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async fetchRootType () {
      try {
        await this.$store.dispatch('getAllRootType')
        this.rootTypeList = this.rootTypeArr.reduce((arr, item, index) => {
          if (index > 4) return arr
          let rootType = {
            id: item.id,
            typeName: item.typeName,
            imgUrl: item.icon
          }
          arr.push(rootType)
          return arr
        }, [])
      } catch (e) {
        console.log(e)
      }
    }
  },
  async mounted () {
    await Promise.all([this.fetchBannerList(), this.fetchRootType()])
    this.setPendding({ num: 1, bool: false })
  }
}
</script>

<style lang="scss">
.home_section_one {
  .home_section_one_top,
  .home_section_one_bottom {
    background-color: #fff;
    margin-bottom: 10px;
  }
  .home_banner {
    height: 160px;
  }
  .just_show {
    display: flex;
    justify-content: space-between;
    @include pagePadding();
    padding-top: 15px;
    padding-bottom: 15px;
    & > div {
      letter-spacing: 2px;
      @include pagePadding(30px);
      text-align: center;
      p {
        font-size: 18px;
      }
      span {
        display: inline-block;
        font-size: 12px;
        color: $fc-sub;
      }
    }
  }
  .home_section_one_bottom {
    @include pagePadding();
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .rootType_list {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .perType {
    flex: 1;
    overflow: hidden;
    padding-top: 15px;
    img {
      display: block;
      margin: 0 auto;
      width: 45px;
      height: 45px;
    }
    span {
      display: inline-block;
      width: 100%;
      @include text-ellipsis();
      text-align: center;
      font-size: 12px;
      color: $fc-sub;
      margin-top: 10px;
    }
  }
}
</style>
