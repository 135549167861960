<template>
  <div class="home_section_three">
    <div class="home_section_three_top"
         @click="$router.push({name:'recommend'})"></div>
    <div v-if="matterList.length > 0"
         class="home_section_three_middle">
      <div class="app_section_title">
        <span class="title_content">热门回收品类</span>
        <a @click.prevent="goTypeView">查看全部</a>
      </div>
      <div class="matter_list">
        <div class="permatter"
             v-for="(matter, index) of matterList"
             :key="index">
          <img v-lazy="matter.titleUrl">
          <p>{{matter.matterName}}</p>
          <div class="maxprice">最高回收价
            <span>¥{{matter.maxPrice || 0}}</span>
          </div>
          <button @click="handleMatterClick(matter)"
                  class="btn">立即回收</button>
        </div>
      </div>
    </div>
    <div class="home_section_three_bottom">
      <div class="app_section_title">
        <span class="title_content">其他服务</span>
      </div>
      <div class="service_list">
        <div class="perservice"
             v-for="(service,index) of serviceList"
             :key="index"
             @click="service.handleClick">
          <div :class="service.class"></div>
          <span>{{service.text}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHotMatter } from '@/api'
import { mapMutations } from 'vuex'
const elementA = document.createElement('a')
export default {
  name: 'home-section-three',
  data () {
    return {
      matterList: [], // {id,typeName,imgUrl}
      serviceList: [
        {
          class: 'shop',
          text: '查看门店',
          handleClick: () => {
            this.$router.push({ name: 'shop' })
          }
        },
        {
          class: 'guide',
          text: '回收指南',
          handleClick: () => {
            console.log('guide')
            // this.$router.push({name:''})
            elementA.setAttribute('href', `tel:${this.$_app_phoneNum}`)
            elementA.click()
          }
        },
        {
          class: 'service',
          text: '人工客服',
          handleClick: () => {
            this.$router.push({ name: 'service' })
          }
        },
      ]
    }
  },
  methods: {
    ...mapMutations({
      setPendding: 'SET_HOMESECTION_PENDING'
    }),
    goTypeView () {
      this.$store.commit('SET_TYPEVIEWOBJ', {})
      this.$router.push({ name: 'type' })
    },
    handleMatterClick (matter) {
      this.$router.push({ name: 'matter', params: { id: matter.id } })
    },
    async fetchMatter () {
      try {
        let list = await getHotMatter()
        this.matterList = list.map((item) => {
          let { id, matterName, maxPrice, typeId, titleUrl } = item
          return { id, matterName, maxPrice, typeId, titleUrl }
        })
      } catch (e) {
        console.log(e)
      }
    }
  },
  async mounted () {
    await this.fetchMatter()
    this.setPendding({ num: 3, bool: false })
  }
}
</script>

<style lang="scss">
.home_section_three {
  .home_section_three_top {
    height: 65px;
    margin-bottom: 10px;
    background-image: url('../assets/img/post.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .home_section_three_middle,
  .home_section_three_bottom {
    background-color: #fff;
    margin-bottom: 10px;
    @include pagePadding();
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .matter_list {
    overflow-x: auto;
    display: flex;
    margin-top: 15px;
  }
  .permatter:not(:last-child) {
    margin-right: 10px;
  }
  .permatter {
    flex: 0 0 auto;
    width: 40%;
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;

    img {
      display: block;
      margin: 0 auto;
      width: 65px;
      height: 65px;
    }
    p {
      margin: 15px 0 20px;
    }
    .maxprice {
      font-size: 12px;
      width: 100%;
      overflow: hidden;
      @include text-ellipsis();
      span {
        color: #fa6408;
      }
    }

    .btn {
      margin-top: 12px;
      height: 30px;
      line-height: 30px;
      width: 100%;
    }
  }
  .service_list {
    display: flex;
    padding-top: 15px;
    .perservice {
      text-align: center;
      flex: 1;
      div {
        width: 45px;
        height: 45px;
        margin: 0 auto 15px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      div.shop {
        background-image: url('../assets/img/shop.png');
      }
      div.guide {
        background-image: url('../assets/img/guide.png');
      }
      div.service {
        background-image: url('../assets/img/service.png');
      }
    }
  }
}
</style>
