<template>
  <div class="app_container home_container">
    <app-top-search @SEARCH_CLICK="$router.push({name:'search'})" />
    <div class="home_page_content">
      <ui-app-loading v-show="homePagePendding" />
      <home-section-one />
      <home-section-two />
      <home-section-three />
      <home-section-four />
    </div>
    <div class="home_page_bottom">
      <a @click.stop
         class="btn"
         :href="$_app_phoneNum">免费报价</a>
    </div>
  </div>
</template>

<script>
import AppTopSearch from '@/components/AppTopSearch'
import HomeSectionOne from '@/components/HomeSectionOne'
import HomeSectionTwo from '@/components/HomeSectionTwo'
import HomeSectionThree from '@/components/HomeSectionThree'
import HomeSectionFour from '@/components/HomeSectionFour'
export default {
  name: 'HomeView',
  components: {
    [AppTopSearch.name]: AppTopSearch,
    [HomeSectionOne.name]: HomeSectionOne,
    [HomeSectionTwo.name]: HomeSectionTwo,
    [HomeSectionThree.name]: HomeSectionThree,
    [HomeSectionFour.name]: HomeSectionFour,
  },
  computed: {
    homePagePendding () {
      return this.$store.getters.homePagePendding
    }
  }
}
</script>

<style lang="scss">
.home_container {
  display: flex;
  flex-flow: column;
  & > div:not(.home_page_content) {
    flex: 0 0 auto;
  }
  .home_page_content {
    flex: 1;
    max-height: calc(100% - 48px);
    overflow: auto;
    padding-bottom: 70px;
    -webkit-overflow-scrolling: touch;
  }
  .home_page_bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    @include pagePadding();
    padding-top: 10px;
    padding-bottom: 10px;
    .btn {
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      text-align: center;
    }
  }
}
</style>