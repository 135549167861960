<template>
  <div id="app">
    <transition :name="transitionName">
      <keep-alive :exclude="excludeView">
        <router-view/>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
export default {
  data () {
    return {
      transitionName: 'fade',
      excludeView: []
    }
  }
}
</script>

