<template>
  <div class="app_top_search">
    <van-icon v-if="showicon"
              name="wap-nav"
              class="topIcon"
              @click="handleIconClick" />
    <input v-model="keyword"
           type="search"
           :placeholder="searchPlaceholder"
           @click="handleClick"
           @search="handleSearch">
    <van-icon name="search"
              class="top_search_icon"
              v-if="showicon" />
  </div>
</template>

<script>
import { Icon } from 'vant'
export default {
  name: 'app-top-search',
  components: {
    [Icon.name]: Icon
  },
  props: {
    searchPlaceholder: {
      type: String,
      default: '点击前往搜索物料'
    },
    showicon: {
      type: Boolean,
      default: true
    },
    // cansearch: {
    //   type: Boolean,
    //   default: false
    // }
  },
  data () {
    return {
      keyword: ''
    }
  },
  methods: {
    handleIconClick () {
      this.$store.commit('SET_TYPEVIEWOBJ', {})
      this.$router.push({ name: 'type' })
    },
    handleClick () {
      this.$emit('SEARCH_CLICK')
    },
    handleSearch () {
      this.$emit('SEARCH_SEARCH', this.keyword)
    }
  },
  activated () {
    this.keyword = ''
  }
}
</script>

<style lang="scss">
.app_top_search {
  background-color: $theme-color;
  height: 48px;
  @include pagePadding();
  display: flex;
  align-items: center;
  position: relative;
  .topIcon {
    font-size: 20px;
    padding-right: 15px;
  }
  & > input {
    flex: 1;
  }
  .top_search_icon {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
